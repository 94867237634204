<template>
  <div class="flex xs6 xl6">

    <vac-card
      class="chart-widget"
      :title="$t('Insights Per Time')">

      <Actions :export-to-excel="exportToExcel" />

<!--      <va-chart :data="data" type="line" :style="{height: '500px'}" />-->
      <line-chart-generator
        :chart-id="'line-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :chart-options="chartOptions"
        :height="500"
        :css-classes="'line-chart-exportable export'"
      />
    </vac-card>

  </div>
</template>

<script>
  import Actions from "../actions/Actions";
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
  )

  const ExportJsonExcel = require('js-export-excel');

  export default {
    name: 'InsightPerTime',
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      Actions,
      LineChartGenerator
    },
    computed: {
      chartOptions() {
        return {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0 // disables bezier curves
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                padding: 20,
                usePointStyle: true,
                pointStyle: 'circle'
              }
            },
          }
        }
      }
    },
    methods: {
      exportToExcel() {
        const { labels, datasets } = this.data;
        const dataSheet = [];

        const excelLabels = JSON.parse(JSON.stringify(labels))

        datasets.forEach(data => {
          const obj = { Retailer: data.label, Total: 0 };
          data.data.forEach((info, index) => {
            obj[excelLabels[index]] = info;
            obj.Total += info;
          });
          dataSheet.push(obj);
        });

        excelLabels.unshift('Retailer', 'Total');

        const option = {
          fileName: 'Insights Per Time',
          datas: [
            {
              sheetData: dataSheet,
              sheetName: 'sheet',
              sheetFilter: excelLabels,
              sheetHeader: excelLabels,
              columnWidths: [16],
            },
          ],
        };

        const toExcel = new ExportJsonExcel(option); // new
        toExcel.saveExcel();
      },
    },
  };
</script>

<style lang="scss" scoped>
.line-chart-exportable {
  height: 500px;
}
</style>
